@import 'ngx-toastr/toastr';


$color-60: #eef0f8;
$color-30: #00a34f;
$color-10: #6993ff;

.background-color {
  background-color: $color-60;
}

.accent-color {
  background-color: $color-30;
}

.highlight-color {
  background-color: $color-10;
}

.text-white {
  color: #FFF;
}

body {
  margin: 0;
  font-family: roboto,Helvetica,"sans-serif";
  font-size: 14px;

  background-color: $color-60;
}

.container {
  width: 100%;
  max-width: 1200px;

  padding: 0 50px;

  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.card {
  background-color: #FFF;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;

  &.no-padding {
    padding: 0;
  }

  &.contain {
    overflow: hidden;
  }
}

.flag {
  margin: 0 3px;
  outline: #d0d0d0 solid 1px;
}

.emoji {
  font-family: "Noto Color Emoji", sans-serif;
  font-weight: 400;
}

a {
  &.p-button {
    text-decoration: none;
  }
}

.virtual-scrolling-table {
  flex-grow: 1;

  tbody {
    td {
      height: 50px;
      //border: 1px solid #f4f4f5 !important;
      border: none !important;
      padding: 0 1.5rem !important;
    }
  }
}

.form-row {
  display: flex;
  flex-direction: row;

  .field {
    &:not(:first-child) {
      margin-left: 10px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.field {
  label {
    margin-left: 4px;
  }
}

.floating-save-form {
  padding-bottom: 80px;
  margin-bottom: -10px;
}

.white-overlay-background {
  background-color: #FFF;
  box-shadow: 0 -10px 20px #FFF;
}

.p-button-white {
  .p-button-label {
    color: white;
  }
}

.p-button-white:hover {
  background: rgba(255, 255, 255, 0.15) !important;
}

.wrap {
  max-width: 100%;
  white-space: normal;
}

.toast-top-right {
  top: 100px;
}

.toast-success {
  background-color: #00a34f;
}
